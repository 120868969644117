<template>

    <v-expansion-panel v-if="type !== 'wiper' || tankSize"
            :disabled="disabled">
        <v-expansion-panel-header disable-icon-rotate>
            <v-card flat>
                <v-card-title>
                    <v-icon class="mr-1">{{`mdi-${icon}`}}</v-icon>

                    {{ $t(title) }}

                    <v-tooltip bottom
                        min-width="400px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small
                                class="ml-1"
                                v-bind="attrs"
                                v-on="on">
                                mdi-information-outline
                            </v-icon>
                        </template>

                        <div v-if="type === 'timelapse'">
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    Estimated / Real Pictures
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="red--text">Error</v-col>
                                <v-col>Check</v-col>
                                <v-col class="green--text">Ok</v-col>
                                <v-col>Check</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>&lt; 0.7</v-col>
                                <v-col>0.7 - 0.9</v-col>
                                <v-col>0.9 - 1.1</v-col>
                                <v-col>&gt; 1.1</v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Data synced</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>> 15 min</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>
                        </div>

                        <div v-if="type === 'storage'">
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Remaining recording time</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="red--text">Error</v-col>
                                <v-col>Check</v-col>
                                <v-col class="green--text">Ok</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>&lt; 7 days</v-col>
                                <v-col>7 - 30 days</v-col>
                                <v-col>&gt; 30 days</v-col>
                            </v-row>

                            <v-divider dark></v-divider>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Remaining Space</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>&lt; 10%</v-col>
                                    <v-col>10% - 20%</v-col>
                                    <v-col>&gt; 20%</v-col>
                            </v-row>

                            <v-divider dark></v-divider>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">S.M.A.R.T. Status</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>Critical</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Data synced</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>> 1 day</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>
                        </div>

                        <div v-if="type === 'sync'">
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    Folder completion
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="red--text">Error</v-col>
                                <v-col>Check</v-col>
                                <v-col class="green--text">Ok</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>&lt; 30%</v-col>
                                <v-col>30% - 70%</v-col>
                                <v-col>&gt; 70%</v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Data synced</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>> 1 hour</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>
                        </div>

                        <div v-if="type === 'wiper'">
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    Last Wipe done = Last wipe expected
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="red--text">Error</v-col>
                                <v-col>Check</v-col>
                                <v-col class="green--text">Ok</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>unequal</v-col>
                                <v-col>-</v-col>
                                <v-col>equal</v-col>
                            </v-row>

                            <v-divider dark></v-divider>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    Wiper Tank Level
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>&lt; 10%</v-col>
                                <v-col>10% - 30%</v-col>
                                <v-col>&gt; 30%</v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Data synced</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>> 1 day</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>
                        </div>

                        <div v-if="type === 'sensors'">
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    {{ $t('temperature') }}
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>Check</v-col>
                                <v-col class="green--text">Ok</v-col>
                                <v-col>Check</v-col>
                                <v-col class="red--text">Error</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>&lt; 5°</v-col>
                                <v-col>5° - 60°</v-col>
                                <v-col>61° - 75°</v-col>
                                <v-col>&gt; 75°</v-col>
                            </v-row>

                            <v-divider dark></v-divider>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    {{ $t('humidity') }}
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>-</v-col>
                                <v-col>0% - 55%</v-col>
                                <v-col>56% - 70%</v-col>
                                <v-col>&gt; 70%</v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Data synced</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>>= 1 Day</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>
                        </div>

                        <div v-if="type === 'power'">
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="subtitle-2">
                                    {{ $t('voltage') }}
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col class="red--text">Error</v-col>
                                <v-col>Check</v-col>
                                <v-col class="green--text">Ok</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                <v-col>&lt; 22.2V</v-col>
                                <v-col>22.2V - 23.5V</v-col>
                                <v-col>&gt; 23.5V</v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col class="subtitle-2">Data synced</v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" style="width:100%;text-align:center;">
                                    <v-col>>= 1 Day</v-col>
                                    <v-col>-</v-col>
                                    <v-col>-</v-col>
                            </v-row>
                        </div>
                    </v-tooltip>
                </v-card-title>
            </v-card>

            <template v-slot:actions>

                <v-tooltip bottom min-width="150">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs"
                            v-on="on"
                            :color="status >= 2 ? 'error' : status >= 1 ? 'warning' : 'success' ">
                            {{ status >= 2 ? 'mdi-alert-circle' : status >= 1 ? 'mdi-alert' : 'mdi-check' }}
                        </v-icon>
                    </template>
                    <span>{{ hint ? hint : 'Everything is OK' }}</span>
                </v-tooltip>
            </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>

            <TimelapseStatus v-if="type === 'timelapse'"
                :id="id"
                :status="status" />
            <StorageStatus v-else-if="type === 'storage'"
                :id="id"
                :status="status" />
            <SyncStatus v-else-if="type === 'sync'"
                :id="id"
                :status="status" />
            <WiperStatus v-else-if="type === 'wiper'"
                :id="id"
                :version="version"
                :status="status" />
            <SensorsStatus v-else-if="type === 'sensors'"
                :id="id"
                :status="status" />
            <PowerStatus v-else-if="type === 'power'"
                :id="id"
                :status="status" />

        </v-expansion-panel-content>

    </v-expansion-panel>

</template>


<script>
import { rest } from '../_helpers'

import TimelapseStatus from '@/components/ChartTimelapse.vue'
import StorageStatus from '@/components/ChartStorage.vue'
import SyncStatus from '@/components/ChartSync.vue'
import PowerStatus from '@/components/ChartPower.vue'
import SensorsStatus from '@/components/ChartSensors.vue'
import WiperStatus from '@/components/ChartWiper.vue'

const misc = require('../_helpers/misc')

export default {
    props: {
        id: Number,
        version: Number,
        type: String,
        title: String,
        icon: String,
        disabled: {
            default() {
                return false
            },
            type: Boolean,
        },
    },

    data() {
        return {
            tankSize: 0,

            status: 0,
            message: [],

            panels: [
                {
                    name: 'overview',
                    title: 'Overview',
                    icon: 'check-circle',
                    disabled: true,
                },
                {
                    name: 'timelapse',
                    title: 'Timelapse',
                    icon: 'image',
                },
                {
                    name: 'storage',
                    title: 'Storage',
                    icon: 'chart-pie',
                },
                {
                    name: 'sync',
                    title: 'Sync Status',
                    icon: 'database-sync',
                },
                {
                    name: 'wiper',
                    title: 'Wiper Status',
                    icon: 'wiper',
                },
                {
                    name: 'sensors',
                    title: 'Sensors',
                    icon: 'memory',
                },
                {
                    name: 'power',
                    title: 'Power Supply',
                    icon: 'power-plug   ',
                },
            ],
        }
    },

    components: {
        StorageStatus,
        TimelapseStatus,
        SyncStatus,
        PowerStatus,
        SensorsStatus,
        WiperStatus,
    },

    computed: {
        hint() {
            return this.message.join(', ')
        },
    },

    methods: {
        setStatus(data) {
            const { message, level } = misc.getSystemTypeStatus(this.type, data)

            this.message = message
            this.status = level

            this.$emit('set-status', this.status, this.message)
        },
    },

    created() {
        let request
        let response = {}

        if (this.type === 'timelapse') {
            request = rest.getLevel('image', this.id, 1)
                .then(result => {
                    response = {
                        estimatedCount: result.estimated,
                        imageCount: result.real,
                        shutterInterval: result.interval,
                        averageSize: result.avgSize,
                        lastImageDate: result.lastImage,
                    }
                })
        } else if (this.type === 'storage') {
            request = rest.getLevel('disk', this.id)
                .then(result => {
                    response = {
                        partitions: result.partitions,
                        devices: result.devices,
                    }
                })
        } else if (this.type === 'wiper') {
            request = rest.getSeries('water', this.id)
                .then(result => {
                    const { tankSize, wiperCount } = result
                    this.tankSize = tankSize
                    const tankLevel = tankSize ? tankSize - ((wiperCount ? wiperCount : 0) * 10) : 0
                    const waterLevel = tankSize ? (100 / tankSize) * tankLevel : 0

                    response = {
                        waterLevel,
                        updatedAt: result.updatedAt,
                    }
                })
        } else if (this.type === 'sensors') {
            request = rest.getSeries('board', this.id)
                .then(result => {
                    response = {
                        temperature: result.temperature,
                        humidity: result.humidity,
                        updatedAt: result.updatedAt,
                    }
                })
        } else if (this.type === 'sync') {
            request = rest.getLevel('sync', this.id)
                .then(result => {
                    response = {
                        syncFolders: result,
                    }
                })
        } else if (this.type === 'power') {
            request = rest.getLevel('power', this.id)
                .then(result => {
                    response = {
                        voltage: result.sys ? result.sys.voltage : 0,
                        estimatedVoltage: result.voltage,
                        routerName: result.routerMake,
                        vpn: result.vpn,
                        updatedAt: result.updatedAt,
                    }
                })
        }

        request
            .catch(e => {
                console.log(`Error while getting data for ${this.type}`, e)
            })
            .finally(() => {
                this.setStatus(response)
            })
    },
}
</script>

<style lang="stylus">
</style>
