<template>

      <div>
        <v-row v-if="smartGraph">
            <v-col
                v-for="(seriesData, idx) in smartGraph"
                :key="'smart_'+idx">
                <v-card flat>
                    <v-card-title class="pb-0">
                        <v-icon class="mr-1">
                            mdi-harddisk
                        </v-icon>
                        Device {{ seriesData.device.name }}
                    </v-card-title>
                    <v-card-title class="py-0">
                        <p class="caption">{{ seriesData.model_family ? `(${seriesData.model_family})` : '' }}</p>
                    </v-card-title>

                    <v-row>
                        <v-col cols="3" class="pt-0">
                            <v-list v-if="seriesData.version === 0"
                                disabled :max-width="300">
                                <v-list-item v-for="value, key, idx in seriesData.conditions" :key="idx">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t(key.split(/(?=[A-Z])/).map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' ')) }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <span>
                                            {{ value }}
                                        </span>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>

                            <v-list v-else
                                disabled :max-width="300">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('status') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon :color="seriesData.smart_status.passed ? 'success' : 'error'">
                                            {{ seriesData.smart_status.passed ? 'mdi-check' : 'mdi-close' }}
                                        </v-icon>
                                    </v-list-item-icon>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('temperature') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <span
                                            :class="(seriesData.temperature.current > 80) ? 'red--text' : ''">
                                            {{ seriesData.temperature.current }}° C
                                        </span>
                                    </v-list-item-icon>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Power On</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <span>{{ seriesData.power_on_time.hours }} Hours</span>
                                    </v-list-item-icon>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>CRC Errors</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <span :class="(seriesData.temperature.current > 80) ? 'red--text' : ''">
                                            {{ seriesData.conditions['199'].raw.value }}
                                        </span>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card flat>
                    <v-card-title class="pb-0">
                        {{ $t('disk_partitions') }}
                    </v-card-title>
                    <v-card-title :class="['pt-0', dateColor()]">
                        <p class="caption">({{ diskDate() }})</p>
                    </v-card-title>

                    <VueApexCharts class="mb-2"
                        height="350"
                        :options="diskChart()"
                        :series="diskGraph.series"/>
                </v-card>
            </v-col>
        </v-row>

      </div>

</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { rest } from '../_helpers'

const moment = require('moment-timezone')


export default {
    props: {
        id: Number,
    },

    data() {
        return {
            diskGraph: [],
            smartGraph: [],
            lineOptions: {
                chart: {
                    height: 350,
                    type: 'area',
                    zoom: {
                        enabled: true,
                    },
                    toolbar: {
                        show: true,
                        offsetX: -20,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true,
                            customIcons: [],
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    align: 'left',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                tooltip: {
                    y: {
                        formatter: (value, {
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }) => {
                            const data = this.diskGraph.series[seriesIndex]

                            return `${value}% (${data.used[dataPointIndex]} GB of ${data.available} GB)`
                        },
                    },
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    labels: {
                        formatter: x => `${x} %`,
                    },
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                    offsetY: 8,
                },
            },
            donutOptions: {
                chart: {
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true,
                                    formatter(w) {
                                        return w.globals.seriesTotals.reduce((a, b) => `${(parseFloat(a) + b).toFixed(2)} GB`, 0)
                                    },
                                },
                            },
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: x => `${x} GB`,
                    },
                },
                dataLabels: {
                    style: {
                        fontSize: '25px',
                    },
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                }],
            },
        }
    },

    components: {
        VueApexCharts,
    },

    computed: {
    },

    methods: {
        diskChart() {
            const options = ({ ...this.lineOptions })
            options.xaxis.categories = this.diskGraph.dateSeries
            return options
        },

        diskDate() {
            return this.diskGraph.lastCheck ? moment(this.diskGraph.lastCheck).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },

        dateColor() {
            return moment(this.diskGraph.lastCheck).isBefore(moment().subtract(1, 'day')) ? 'red--text' : ''
        },
    },

    created() {
        rest.getSeries('disk', this.id)
            .then(result => {
                this.diskGraph = result.partitions

                const smartGraph = []
                result.devices.forEach(i => {
                    if (i.device) {
                        smartGraph.push(i)
                    }
                })
                this.smartGraph = smartGraph
            })
    },
}
</script>

<style lang="stylus">
</style>
