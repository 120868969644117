<template>
    <v-expansion-panels
    v-model="panel"
        multiple>

        <v-expansion-panel
            :disabled="!!messages.length">
            <v-expansion-panel-header disable-icon-rotate>
                <v-card flat>
                    <v-card-title>
                        <v-icon class="mr-1">
                            mdi-check-circle
                        </v-icon>
                        {{ $t('overview') }}
                    </v-card-title>
                </v-card>

                <template v-slot:actions>

                    <v-tooltip bottom min-width="150">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                v-on="on"
                                :color="status >= 2 ? 'error' : status >= 1 ? 'warning' : 'success' ">
                                {{ status >= 2 ? 'mdi-alert-circle' : status >= 1 ? 'mdi-alert' : 'mdi-check' }}
                            </v-icon>
                        </template>
                        <span v-for="(item, key, idx) in messages"
                            :key="idx" >
                            <strong>{{ key.toUpperCase()}}</strong>: {{ item .length ? item.join(', ') : $t('everything_ok') }}<br/>
                        </span>
                    </v-tooltip>
                </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>

                <template v-for="(item, key, idx) in messages">
                    <v-card v-if="!!item.length"
                        :key="idx"
                        flat>

                        <v-card-text>
                            <strong>{{ key.toUpperCase()}}</strong>: {{ item.join(', ')}}
                        </v-card-text>
                    </v-card>
                </template>

            </v-expansion-panel-content>

        </v-expansion-panel>

        <ChartPanel v-for="item in usedPanels"
            :key="item.name"
            :id="id"
            :version="version"
            :title="item.title"
            :icon="item.icon"
            :type="item.name"
            v-on:set-status="setMessages(item.name, arguments[0], arguments[1])" />

    </v-expansion-panels>

</template>


<script>
import ChartPanel from '@/components/ChartPanel.vue'


export default {
    props: {
        id: Number,
        version: Number,
        customGauges: {
            default() {
                return {}
            },
            type: Object,
        },
    },

    data() {
        return {
            panel: [],
            status: 0,
            messages: {},
            panels: [
                {
                    name: 'timelapse',
                    title: 'Timelapse',
                    icon: 'image',
                },
                {
                    name: 'storage',
                    title: 'Storage',
                    icon: 'chart-pie',
                },
                {
                    name: 'sync',
                    title: 'Sync Status',
                    icon: 'database-sync',
                },
                {
                    name: 'wiper',
                    title: 'Wiper Status',
                    icon: 'wiper',
                },
                {
                    name: 'sensors',
                    title: 'Sensors',
                    icon: 'memory',
                },
                {
                    name: 'power',
                    title: 'Power Supply',
                    icon: 'power-plug   ',
                },
            ],
        }
    },

    components: {
        ChartPanel,
    },

    computed: {
        usedPanels() {
            const selectedPanels = []
            this.panels.forEach(panel => {
                if (this.customGauges[panel.name] === undefined
                    || this.customGauges[panel.name] === true) {
                    selectedPanels.push(panel)
                }
            })

            return selectedPanels
        },
    },

    methods: {
        setMessages(type, status, messages) {
            if (status > this.status) {
                this.status = status
            }

            this.$set(this.messages, type, messages)

            const allMessages = Object.keys(this.messages).reduce((res, v) => res.concat(this.messages[v]), [])

            if (this.status > 0 && this.panel.indexOf(0) < 0 && allMessages.length) {
                this.panel.push(0)
            }
        },
    },

    created() {

    },

    watch: {
        customGauges() {
        },
    },
}
</script>

<style lang="stylus">
</style>
