import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('daily_images'))+" ")]),_c(VCardTitle,{class:['pt-0', _vm.dateColor(_vm.imageGraph.last)]},[_c('p',{},[_vm._v(_vm._s(_vm.$t('last_image'))+": "+_vm._s(_vm.formatDate(_vm.imageGraph.last)))])]),(_vm.diskGraph && (_vm.diskGraph.bytesAvailable || _vm.diskGraph.bytesTotal))?_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('remaining'))+": "+_vm._s(_vm.remainingImages)+" Days ")]):_vm._e(),_c(VCardSubtitle,{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('recording_since'))+": "+_vm._s(_vm.formatDate(_vm.imageGraph.first))+" ")])],1)],1),_c(VRow,[_c(VCol,{staticClass:"px-4",attrs:{"id":"date-slider"}},[_c(VSlider,{staticClass:"align-center",attrs:{"max":_vm.max,"min":_vm.min,"thumb-label":"always","hide-details":""},on:{"end":_vm.changeDate},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" "+_vm._s(_vm.startDate.format('YYYY-MM-DD'))+" ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.endDate.format('YYYY-MM-DD'))+" ")]},proxy:true},{key:"thumb-label",fn:function(ref){return undefined}}]),model:{value:(_vm.slider),callback:function ($$v) {_vm.slider=$$v},expression:"slider"}})],1)],1),_c(VExpansionPanels,[_c(VExpansionPanel,{staticClass:"my-6"},[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('Weather')))]),_c(VExpansionPanelContent,[_c('Weather',{attrs:{"id":_vm.id,"period":_vm.period,"toDate":_vm.weatherEndDate}})],1)],1)],1),_c(VRow,{staticClass:"mb-2"},[_c('VueApexCharts',{staticStyle:{"width":"100%"},attrs:{"height":"350","options":_vm.imageChart,"series":_vm.imageGraph.series}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }