<template>

    <div v-if="boardData">
        <v-row>
            <v-col>
                <v-card flat>
                    <v-card-title>
                        Board
                    </v-card-title>
                    <v-card-subtitle :class="['pb-0', dateColor]">
                        ({{ formatDate(updatedAt) }})
                    </v-card-subtitle>

                    <v-row>
                        <v-col cols="3">
                            <v-list disabled :max-width="300">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Version</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <span>
                                            {{ boardData.version }}
                                        </span>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                :cols="6">
                <v-card flat>
                    <v-card-title class="pb-0">
                        <v-icon class="mr-1">mdi-thermometer</v-icon>{{ $t('temperature') }}
                    </v-card-title>

                    <VueApexCharts
                        type="radialBar"
                        height="350"
                        :options="boardOptions('temp')"
                        :series="boardSeries.temp.series" />
                </v-card>
            </v-col>
            <v-col
                :cols="6">
                <v-card flat>
                    <v-card-title class="pb-0">
                        <v-icon class="mr-1">mdi-water-percent</v-icon>{{ $t('humidity') }}
                        <!-- <v-btn icon
                            aria-label="Dryer"
                            outlined
                            class="ml-5">
                            <v-icon>
                                mdi-hair-dryer
                            </v-icon>
                        </v-btn> -->
                    </v-card-title>

                    <VueApexCharts
                        type="radialBar"
                        height="350"
                        :options="boardOptions('hum')"
                        :series="boardSeries.hum.series" />
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="px-4" id="date-slider">
               <v-slider
                    v-model="slider"
                    class="align-center"
                    :max="max"
                    :min="min"
                    thumb-label="always"
                    hide-details
                    @end="changeDate"
                >
                    <template v-slot:prepend>
                        {{ startDate.format('YYYY-MM-DD') }}
                    </template>

                    <template v-slot:append>
                        {{ endDate.format('YYYY-MM-DD') }}
                    </template>

                    <template v-slot:thumb-label="{ }">

                    </template>
                </v-slider>
            </v-col>
        </v-row>

        <v-expansion-panels>
            <v-expansion-panel class="my-6">
                <v-expansion-panel-header>{{ $t('Weather') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Weather
                        :id="id"
                        :period="period"
                        :toDate="weatherEndDate"></Weather>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row class="mb-2">
            <v-col>
                <VueApexCharts
                    height="350"
                    style="width:100%"
                    :options="historyChart"
                    :series="boardSeries.series" />
            </v-col>
        </v-row>

      </div >

</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { rest } from '../_helpers'

const Weather = () => import(
    /* webpackChunkName: "system-weather" */ '@/components/Weather.vue'
)

const moment = require('moment-timezone')


export default {
    props: {
        id: Number,
    },

    data() {
        return {
            min: 0,
            max: 90,
            slider: 90,
            period: 365,
            weatherEndDate: null,

            updatedAt: null,
            boardData: null,
            boardSeries: {
                temp: {
                    series: [],
                    max: 60,
                    min: 10,
                    name: this.$t('temperature'),
                },
                hum: {
                    series: [],
                    max: 70,
                    min: 40,
                    name: this.$t('humidity'),
                },
            },
            barOptions: {
                chart: {
                    height: 350,
                    stacked: false,
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'center',
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [0, 1],
                    // offsetY: 15,
                    style: {
                        fontSize: '12px',
                    },
                    background: {
                        opacity: 1,
                        borderColor: 'rgba(0,0,0,0)',
                    },
                },
                tooltip: {
                    y: {
                        formatter: (val, {
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }) => {
                            switch (seriesIndex) {
                            case 1:
                                return `${val} %`
                            case 0:
                                return `${val} °C`
                            default:
                                return val
                            }
                        },
                    },
                },
                noData: {
                    text: this.$t('no_data'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined,
                    },
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    curve: 'smooth',
                },
                series: [],
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: 0, // no need to rotate since hiding labels gives plenty of room
                        hideOverlappingLabels: true, // all labels must be rendered
                        formatter: (value, timestamp, opts) => value,
                    },
                },
                yaxis: [{
                    title: {
                        text: this.$t('temperature'),
                    },
                }, {
                    opposite: true,
                    title: {
                        text: this.$t('humidity'),
                    },
                }],
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                    // position: 'right',
                    offsetY: 8,
                },
            },
            gaugeOptions: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                    toolbar: {
                        show: true,
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,

                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '17px',
                            },
                            value: {
                                formatter: (val, args) => {
                                    if (args.globals.labels[0] === this.$t('humidity')) {
                                        return `${parseInt(val, 10)} %`
                                    }
                                    if (args.globals.labels[0] === this.$t('temperature')) {
                                        return `${parseInt(val, 10)} °C`
                                    }
                                    return parseInt(val, 10)
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            },
                        },
                    },
                },
            },
        }
    },

    components: {
        VueApexCharts,
        Weather,
    },

    computed: {
        dateColor() {
            return moment(this.updatedAt).isBefore(moment().subtract(1, 'day')) ? 'red--text' : ''
        },

        historyChart() {
            const options = ({ ...this.barOptions })
            if (this.boardSeries.categories) {
                options.xaxis.categories = this.boardSeries.categories
            }
            return options
        },

        startDate() {
            const minusDays = (this.max - this.slider) + this.period
            const date = moment().subtract(minusDays, 'd')
            return date
        },

        endDate() {
            const minusDays = this.max - this.slider
            const date = moment().subtract(minusDays, 'd')
            return date
        },
    },

    methods: {
        formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },

        boardOptions(type) {
            const currentVal = this.boardSeries[type].series[0]

            const extraOptions = {
                labels: [this.boardSeries[type].name],
                fill: {
                    colors: ['#3ccb4d'],
                },
            }
            if (currentVal >= this.boardSeries[type].max) {
                extraOptions.fill.colors = ['#ff5733']
            } else if (currentVal <= this.boardSeries[type].min) {
                extraOptions.fill.colors = ['#33b8ff']
            }

            return ({ ...this.gaugeOptions, ...extraOptions })
        },

        changeDate() {
            this.weatherEndDate = this.endDate.toDate()

            const payload = {
                period: this.period,
                to: this.endDate.format('YYYY-MM-DD HH:mm:ssZ'),
            }

            rest.getSeries('board', this.id, payload)
                .then(result => {
                    if (Object.keys(result).length) {
                        this.boardData = result

                        this.boardSeries.temp.series = [this.boardData.temperature]
                        this.boardSeries.hum.series = [this.boardData.humidity]

                        this.boardSeries.categories = this.boardData.categories
                        this.boardSeries.series = this.boardData.series
                        this.boardSeries.series.forEach(serie => {
                            serie.name = this.$t(serie.name)
                        })

                        this.updatedAt = result.updatedAt
                    }
                })
        },
    },

    created() {
        const payload = {
            period: this.period,
            to: this.endDate,
        }

        rest.getSeries('board', this.id, payload)
            .then(result => {
                if (Object.keys(result).length) {
                    this.boardData = result

                    this.boardSeries.temp.series = [this.boardData.temperature]
                    this.boardSeries.hum.series = [this.boardData.humidity]

                    this.boardSeries.categories = this.boardData.categories
                    this.boardSeries.series = this.boardData.series
                    this.boardSeries.series.forEach(serie => {
                        serie.name = this.$t(serie.name)
                    })

                    this.updatedAt = result.updatedAt
                }
            })
    },
}
</script>

<style lang="stylus">
</style>
