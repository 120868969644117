<template>
    <div>
        <v-row>
            <v-col class="px-4" id="date-slider">
               <v-slider
                    v-model="slider"
                    class="align-center"
                    :max="max"
                    :min="min"
                    thumb-label="always"
                    hide-details
                    @end="changeDate"
                >
                    <template v-slot:prepend>
                        {{ startDate.format('YYYY-MM-DD') }}
                    </template>

                    <template v-slot:append>
                        {{ endDate.format('YYYY-MM-DD') }}
                    </template>

                    <template v-slot:thumb-label="{ }">

                    </template>
                </v-slider>
            </v-col>
        </v-row>

        <v-expansion-panels>
            <v-expansion-panel class="my-6">
                <v-expansion-panel-header>{{ $t('Weather') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Weather
                        :id="id"
                        :period="period"
                        :toDate="weatherEndDate"></Weather>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
            <v-col>
                <v-card flat>
                    <VueApexCharts
                        height="350"
                        :options="routerChart"
                        :series="routerGraph.series"/>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card flat>
                    <v-card-title>
                        <v-icon class="mr-1">mdi-router-wireless</v-icon>{{ $t('Router') }}
                    </v-card-title>
                    <v-card-subtitle :class="['caption', 'pb-0', dateColor]">
                        ({{ formatDate(updatedAt) }})
                    </v-card-subtitle>

                    <v-expansion-panels focusable flat multiple>
                        <v-expansion-panel
                        v-for="(key, idx) in Object.keys(routerData).sort()"
                        :key="idx"
                        >
                        <v-expansion-panel-header>
                            {{ key.toUpperCase() }}
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <v-simple-table>
                                <template v-slot:default>
                                <tbody>
                                    <tr
                                    v-for="(data, ikey) in routerData[key]"
                                    :key="ikey"
                                    >
                                    <td style="text-transform: capitalize;">{{ getDataFieldName(ikey) }}</td>
                                    <td>{{ data }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>

    </div>

</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { rest } from '../_helpers'

const Weather = () => import(
    /* webpackChunkName: "system-weather" */ '@/components/Weather.vue'
)

const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
    },

    data() {
        return {
            min: 0,
            max: 90,
            slider: 90,
            period: 14,
            weatherEndDate: null,

            routerData: {},
            updatedAt: null,
            routerGraph: {},
            lineOptions: {
                chart: {
                    height: 350,
                    type: 'area',
                    zoom: {
                        enabled: true,
                    },
                    toolbar: {
                        show: true,
                        offsetX: -20,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true,
                            customIcons: [],
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    align: 'left',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                tooltip: {
                    y: {
                        formatter(val, {
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }) {
                            switch (seriesIndex) {
                            case 0:
                                return `${val} V`
                            case 1:
                                return `${val ? val : '--'} °C`
                            default:
                                return `${val}`
                            }
                        },
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: 0, // no need to rotate since hiding labels gives plenty of room
                        hideOverlappingLabels: true, // all labels must be rendered
                        formatter: (value, timestamp, opts) => value,
                    },
                },
                yaxis: [{
                    labels: {
                        formatter(val) {
                            return val.toFixed(1)
                        },
                    },
                    min: ymin => ymin - 2,
                    max: ymax => ymax + 2,
                    title: {
                        text: this.$t('voltage'),
                    },
                }, {
                    opposite: true,
                    title: {
                        text: this.$t('temperature'),
                    },
                }],
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                    showForSingleSeries: true,
                    offsetY: 8,
                },
            },
        }
    },

    components: {
        VueApexCharts,
        Weather,
    },

    computed: {
        dateColor() {
            return moment(this.updatedAt).isBefore(moment().subtract(1, 'day')) ? 'red--text' : ''
        },

        routerChart() {
            const options = ({ ...this.lineOptions })
            options.xaxis.categories = this.routerGraph.categories
            return options
        },

        startDate() {
            const minusDays = (this.max - this.slider) + this.period
            const date = moment().subtract(minusDays, 'd')
            return date
        },

        endDate() {
            const minusDays = this.max - this.slider
            const date = moment().subtract(minusDays, 'd')
            return date
        },
    },

    methods: {
        formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },

        getDataFieldName(value) {
            const name = value ? value.replace(/_/g, ' ') : ''
            return name
        },

        changeDate() {
            this.weatherEndDate = this.endDate.toDate()

            const payload = {
                period: this.period,
                to: this.endDate.format('YYYY-MM-DD HH:mm:ssZ'),
            }

            rest.getSeries('router', this.id, payload)
                .then(result => {
                    this.routerGraph = result

                    this.routerGraph.series.forEach(serie => {
                        serie.name = this.$t(serie.name)
                    })
                })
        },
    },

    created() {
        rest.getRouterData(this.id)
            .then(result => {
                const routerData = result
                if (routerData) {
                    this.updatedAt = result.updatedAt
                    delete routerData.updatedAt

                    this.routerData = routerData
                }
            })

        const payload = {
            period: this.period,
            to: this.endDate,
        }

        rest.getSeries('router', this.id, payload)
            .then(result => {
                this.routerGraph = result

                this.routerGraph.series.forEach(serie => {
                    serie.name = this.$t(serie.name)
                })
            })
    },
}
</script>

<style lang="stylus">
</style>
