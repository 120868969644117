<template>

        <v-row>
            <v-col>
                <v-card flat>
                    <v-row>
                        <v-col cols="3">
                            <v-card-title>
                                <v-icon class="mr-1">mdi-propane-tank</v-icon>{{ $t('tank_level')}}
                            </v-card-title>
                            <v-card-subtitle :class="['pb-0', dateColor]">
                                ({{ updatedAt ? formatDate(updatedAt) : '---' }})
                            </v-card-subtitle>
                            <v-card-subtitle class="pb-0">
                                {{ $t('filled') }}: {{ tankLevel >= 0 ? tankLevel : '--' }} ml / {{ tankSize }} ml
                            </v-card-subtitle>
                            <v-card-subtitle class="pb-0">
                                {{ $t('wipes_left') }}: {{ wipesLeft >= 0 ? wipesLeft : '--' }}
                            </v-card-subtitle>
                            <v-card-subtitle class="pb-0">
                                {{ $t('empty_at') }}: {{ emptyAt }}
                            </v-card-subtitle>
                            <v-card-subtitle v-if="tankLevel >= 0 && version > 0" class="mt-8 pb-0">
                                <v-btn
                                    :disabled="disabled"
                                    :color="refillStatusColor"
                                    @click="refill()">
                                    <v-icon class="mr-1">mdi-gas-station</v-icon>
                                    {{ $t('refill') }}
                                </v-btn>
                            </v-card-subtitle>
                        </v-col>

                        <v-col>
                            <div class="cup"
                                :style="{'background-image': `url(/water_${levelPercent>=20 ? 'good' : 'bad'}.png)`, 'background-position-y': `${180-( levelPercent/100*170)}px`}">
                                <span :class="['text-h3', 'water-level', (levelPercent <20 ? 'red--text text--lighten-1' : '')    ]">{{ levelPercent >= 0 ? levelPercent.toFixed(0) : '--' }}%</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

</template>


<script>
import { rest } from '../_helpers'

const moment = require('moment-timezone')


export default {
    props: {
        id: Number,
        version: Number,
    },

    data() {
        return {
            wiperCount: 0,
            tankSize: 0,
            updatedAt: null,
            usagePerWipe: 10,
            tankLevel: 0,
            refillStatusColor: '',
            disabled: false,
        }
    },

    computed: {
        wipesLeft() {
            return this.tankLevel / this.usagePerWipe
        },

        levelPercent() {
            return this.tankSize ? (100 / this.tankSize) * this.tankLevel : 0
        },

        dateColor() {
            return moment(this.updatedAt).isBefore(moment().subtract(1, 'day')) ? 'red--text' : ''
        },

        emptyAt() {
            const weeksLeft = this.wipesLeft / 4
            return moment.utc().add(weeksLeft, 'weeks').format('YYYY-MM-DD')
        },
    },

    methods: {
        getDataFieldName(value) {
            const name = value.replace(/_/g, ' ')
            return name
        },

        formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },

        refill() {
            this.disabled = true
            rest.doWaterRefill(this.id)
                .then(result => {
                    this.refillStatusColor = result.success ? 'success' : 'error'
                })
                .catch(err => {
                    this.refillStatusColor = 'error'
                })
                .finally(() => {
                    this.disabled = false
                    setTimeout(() => { this.refillStatusColor = '' }, 2000)
                })
        },
    },

    created() {
        rest.getSeries('water', this.id)
            .then(result => {
                if (Object.keys(result).length) {
                    this.wiperCount = result.wiperCount
                    this.tankSize = result.tankSize
                    this.updatedAt = result.updatedAt
                    this.usagePerWipe = result.usagePerWipe
                    this.tankLevel = (this.wiperCount >= 0 && this.usagePerWipe) ? this.tankSize - (this.wiperCount * this.usagePerWipe) : result.tankLevel
                }
            })
    },
}
</script>


<style lang="stylus">
    @keyframes fillY
        0%
            background-position-y 0
        100%
            background-position-y 520px

    @keyframes fillX
        0%
            background-position-x 0px
        100%
            background-position-x 260px

    .cup
        margin 50px
        width 150px
        height 180px
        border 6px solid #fff
        border-top 2px solid transparent
        border-radius 15px
        border-top-left-radius 5px
        border-top-right-radius 5px
        animation fillX 5s linear infinite
        background-repeat repeat-x
        background-size 260px 170px
        box-shadow 0 0 0 6px #ddd
        position relative

    .water-level
        position absolute
        top calc( 50% - .5em )
        width 100%
        text-align center
        height 1em
</style>
