import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VCard,{attrs:{"flat":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-propane-tank")]),_vm._v(_vm._s(_vm.$t('tank_level'))+" ")],1),_c(VCardSubtitle,{class:['pb-0', _vm.dateColor]},[_vm._v(" ("+_vm._s(_vm.updatedAt ? _vm.formatDate(_vm.updatedAt) : '---')+") ")]),_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('filled'))+": "+_vm._s(_vm.tankLevel >= 0 ? _vm.tankLevel : '--')+" ml / "+_vm._s(_vm.tankSize)+" ml ")]),_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('wipes_left'))+": "+_vm._s(_vm.wipesLeft >= 0 ? _vm.wipesLeft : '--')+" ")]),_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('empty_at'))+": "+_vm._s(_vm.emptyAt)+" ")]),(_vm.tankLevel >= 0 && _vm.version > 0)?_c(VCardSubtitle,{staticClass:"mt-8 pb-0"},[_c(VBtn,{attrs:{"disabled":_vm.disabled,"color":_vm.refillStatusColor},on:{"click":function($event){return _vm.refill()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-gas-station")]),_vm._v(" "+_vm._s(_vm.$t('refill'))+" ")],1)],1):_vm._e()],1),_c(VCol,[_c('div',{staticClass:"cup",style:({'background-image': ("url(/water_" + (_vm.levelPercent>=20 ? 'good' : 'bad') + ".png)"), 'background-position-y': ((180-( _vm.levelPercent/100*170)) + "px")})},[_c('span',{class:['text-h3', 'water-level', (_vm.levelPercent <20 ? 'red--text text--lighten-1' : '')    ]},[_vm._v(_vm._s(_vm.levelPercent >= 0 ? _vm.levelPercent.toFixed(0) : '--')+"%")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }