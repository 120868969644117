<template>
    <div>
        <v-row>
            <v-card flat>
                <v-card-title>
                    {{ $t('daily_images') }}
                </v-card-title>
                <v-card-title :class="['pt-0', dateColor(imageGraph.last)]">
                    <p class="">{{ $t('last_image') }}: {{ formatDate(imageGraph.last) }}</p>
                </v-card-title>

                <v-card-subtitle v-if="diskGraph && (diskGraph.bytesAvailable || diskGraph.bytesTotal)" class="pb-0">
                    {{ $t('remaining') }}: {{ remainingImages }} Days
                </v-card-subtitle>
                <v-card-subtitle class="pt-1">
                    {{ $t('recording_since') }}: {{ formatDate(imageGraph.first) }}
                </v-card-subtitle>
            </v-card>
        </v-row>

        <v-row>
            <v-col class="px-4" id="date-slider">
               <v-slider
                    v-model="slider"
                    class="align-center"
                    :max="max"
                    :min="min"
                    thumb-label="always"
                    hide-details
                    @end="changeDate"
                >
                    <template v-slot:prepend>
                        {{ startDate.format('YYYY-MM-DD') }}
                    </template>

                    <template v-slot:append>
                        {{ endDate.format('YYYY-MM-DD') }}
                    </template>

                    <template v-slot:thumb-label="{ }">

                    </template>
                </v-slider>
            </v-col>
        </v-row>

        <v-expansion-panels>
            <v-expansion-panel class="my-6">
                <v-expansion-panel-header>{{ $t('Weather') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Weather
                        :id="id"
                        :period="period"
                        :toDate="weatherEndDate"></Weather>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row class="mb-2">
            <VueApexCharts
                height="350"
                style="width:100%"
                :options="imageChart"
                :series="imageGraph.series" />
        </v-row>
    </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { rest } from '../_helpers'

const Weather = () => import(
    /* webpackChunkName: "system-weather" */ '@/components/Weather.vue'
)

const moment = require('moment-timezone')


export default {
    props: {
        id: Number,
    },

    data() {
        return {
            imageGraph: {},
            diskGraph: [],
            min: 0,
            max: 90,
            slider: 90,
            period: 15,

            weatherEndDate: null,

            barOptions: {
                chart: {
                    height: 350,
                    stacked: false,
                    // group: 'weather',
                    id: 'timelapse',
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'center',
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0, 1],
                    // offsetY: 15,
                    style: {
                        fontSize: '12px',
                    },
                    background: {
                        opacity: 1,
                        borderColor: 'rgba(0,0,0,0)',
                    },
                },
                // colors: ['rgba(0,143,251,1)', 'rgb(254, 176, 25)'],
                noData: {
                    text: this.$t('no_data'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined,
                    },
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    curve: 'smooth',
                },
                tooltip: {
                    y: {
                        formatter: (val, {
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }) => {
                            let restVal = 0
                            switch (seriesIndex) {
                            case 2:
                                return `${val} MB`
                            case 0:
                                restVal = this.imageGraph.series[seriesIndex].rest[dataPointIndex]
                                return `${val} of ${val + restVal}`
                            default:
                                return val
                            }
                        },
                    },
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                    offsetY: 8,
                },
                series: [],
                xaxis: {
                    categories: [],
                },
                yaxis: [{
                    title: {
                        text: this.$t('image_count'),
                    },
                }, { show: false }, {
                    opposite: true,
                    title: {
                        text: this.$t('average_size'),
                    },
                }],
            },
        }
    },

    components: {
        VueApexCharts,
        Weather,
    },

    computed: {
        startDate() {
            const minusDays = (this.max - this.slider) + this.period
            const date = moment().subtract(minusDays, 'd')
            return date
        },

        endDate() {
            const minusDays = this.max - this.slider
            const date = moment().subtract(minusDays, 'd')
            return date
        },

        timespan() {
            const minusDays = (this.max - this.slider)
            const date = moment().subtract(minusDays, 'd')
            return date
        },

        remainingImages() {
            let remaining = 'Unknown'
            if (this.diskGraph && this.imageGraph.avgSize && this.imageGraph.avgDayCount) {
                const remainingCount = (this.diskGraph.bytesTotal - this.diskGraph.bytesUsed) / this.imageGraph.avgSize
                remaining = Math.floor(remainingCount / this.imageGraph.avgDayCount)
            }

            return remaining
        },

        imageChart() {
            let options = ({ ...this.barOptions })
            if (this.imageGraph.categories) {
                options = Object.assign(
                    options,
                    {
                        xaxis: { categories: this.imageGraph.categories },
                        // series: this.imageGraph.series # if responsive then put series into this object
                    },
                )
            }
            return options
        },
    },

    methods: {
        getDataFieldName(value) {
            const name = value.replace(/_/g, ' ')
            return name
        },

        formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },

        dateColor(date) {
            return date && moment(date).isAfter(moment().subtract(1, 'hour')) ? '' : 'error--text'
        },

        changeDate() {
            this.weatherEndDate = this.endDate.toDate()

            const payload = {
                period: this.period,
                to: this.endDate.format('YYYY-MM-DD HH:mm:ssZ'),
            }

            rest.getSeries('image', this.id, payload)
                .then(result => {
                    this.imageGraph = result
                })
        },
    },

    created() {
        const payload = {
            period: this.period,
            to: this.endDate,
        }

        rest.getSeries('image', this.id, payload)
            .then(result => {
                this.imageGraph = result

                this.imageGraph.series.forEach(serie => {
                    serie.name = this.$t(serie.name)
                })
            })

        rest.getSeries('disk', this.id)
            .then(result => {
                this.diskGraph = result.partitions
            })
    },

    watch: {
    },
}
</script>

<style lang="stylus">
    #date-slider .v-slider__thumb-label
        background-color rgba(0,0,0,0) !important

    #date-slider .v-slider__thumb-label div
        color rgba(0,0,0, 1)
</style>
