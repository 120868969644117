<template>

    <v-row>
        <v-col v-for="(item, idx) in syncSeries"
            :key="idx">
            <v-card flat>
                <v-card-title>
                    <v-icon class="mr-1">mdi-folder</v-icon>
                    {{ item.name }}
                </v-card-title>

                <v-card-subtitle :class="['pb-0', dateColor(item.date)]">
                    ({{ formatDate(item.date) }})
                </v-card-subtitle>

                <VueApexCharts
                    type="radialBar"
                    height="350"
                    :options="syncOptions(item)"
                    :series="item.series" />
            </v-card>
        </v-col>
    </v-row>

</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { rest } from '../_helpers'

const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
    },

    data() {
        return {
            syncData: null,
            syncSeries: [],
            gaugeOptions: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                    toolbar: {
                        show: true,
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,

                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: false,
                                color: '#888',
                                fontSize: '17px',
                            },
                            value: {
                                formatter(val, args) {
                                    return `${parseInt(val, 10)} %`
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            },
                        },
                    },
                },
            },
        }
    },

    components: {
        VueApexCharts,
    },

    methods: {
        formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },

        dateColor(date) {
            return moment(date).isBefore(moment().subtract(1, 'day')) ? 'red--text' : ''
        },

        syncOptions(data) {
            const currentVal = data.series[0]

            const extraOptions = {
                labels: [data.name],
                fill: {
                    colors: ['#3ccb4d'],
                },
            }
            if (currentVal < data.max) {
                extraOptions.fill.colors = ['#ff5733']
            } else if (currentVal <= data.min) {
                extraOptions.fill.colors = ['#33b8ff']
            }

            return ({ ...this.gaugeOptions, ...extraOptions })
        },
    },

    created() {
        rest.getSeries('sync', this.id)
            .then(result => {
                result.forEach(folder => {
                    const serie = {
                        series: [folder.data.v.data.length ? folder.data.v.data[0].completion : 0],
                        max: 70,
                        min: 10,
                        name: `${folder.data.v.name}${folder.data.v.data.length ? ` ⇒ ${folder.data.v.data[0].deviceName}` : ''}`,
                        date: folder.data.v.stateRead,
                    }

                    this.syncSeries.push(serie)
                })
            })
    },
}
</script>

<style lang="stylus">
</style>
